export const tools = {
  toolsMousekeys: {
    viewerSetting: {
      Wwwc: 
        {
          name:'Windowing',
          symbol:"&#8982;"
        },
      WwwcRegion: 
        {
          name:'Windowing (region)',
          symbol:"&#9632;"
        },      
      Pan: 
        {
          name:'Move Image',
          symbol:"&#10036;"
        },      
      Magnify: 
        {
          name:'Magnify',
          symbol:"&#128269;"
        },      Rotate: 
        {
          name:'Rotate Image',
          symbol:"&#8634;"
        },
      Zoom: 
        {
          name:'Zoom Image',
          symbol:"&#128269;"
        },        
      StackScroll: 
        {
          name:'Stack Scroll',
          symbol:"&#8593;&#8595;"
        },    
    },
    annotation: {
      CircleRoi: 
        {
          name:'Circle ROI',
          symbol:"&#11044;"
        },      
      EllipticalRoi: 
        {
          name:'Elliptical ROI',
          symbol:"&#11044;&#8213;"
        },      
      RectangleRoi: 
        {
          name:'Rectangle ROI',
          symbol:"&#9632;"
        },      
      FreehandRoi: 
        {
          name:'Freehand ROI',
          symbol:"&#9998;"
        },      
      Length: 
        {
          name:'Length Measurement',
          symbol:"&#128207;"
        },
      Eraser: 
        {
          name:'Eraser',
          symbol:"&#9003;"
        },    
      },
    segmentation: {
      Brush: 
        {
          name:'Brush Tool',
          symbol:"&#128396;"
        },      
      FreehandScissors: 
        {
          name:'Freehand Scissors Tool',
          symbol:"&#9986;"
        },      
      CorrectionScissors: 
        {
          name:'Freehand Scissors Tool',
          symbol:"&#9986;"
        }, 
      RectangleScissors: 
        {
          name:'Rectangle Scissors Tool',
          symbol:"&#9986;"
        },    }
  },
  toolsMousewheel: {
    viewerSetting: {
      ZoomMouseWheel: {
        name: 'Zoom image',
        symbol:"&#128269;"
      },   
      StackScrollMouseWheel: {
        name: 'Stack scroll',
        symbol:"&#8593;&#8595;"
      }
    }
  }
}
